'use client'

import { useMemo } from 'react'
import useSWRImmutable from 'swr/immutable'
import type { Labels, LabelsNamespaces } from 'types/labels'
import { env } from 'utils/envManager'

import type { LabelsTranslationsService } from '../../types'
import { adapterLabelsTranslations } from '../../utils/adapters/labelsTranslations/adapterLabelsTranslations'

interface UseClientLabelsProps {
	countryISO: string
	languageISO: string
	keys?: string[]
	namespaces?: LabelsNamespaces[]
	suspense?: boolean
}

export function useClientLabels({
	keys,
	namespaces,
	countryISO,
	languageISO,
	suspense = true,
}: UseClientLabelsProps): {
	labels: Labels | undefined
	t: (key: string) => string
} {
	const doFetchKeys = keys && keys.length !== 0
	const doFetchNamespaces = namespaces && namespaces.length !== 0
	const url = `${env.NEXT_PUBLIC_API_DOMAIN}/ws/24h/labels/v1/labels`

	const { data: dataKeys } = useSWRImmutable<LabelsTranslationsService | null>(
		[keys],
		() => {
			const urlKeys = new URL(url)
			urlKeys.searchParams.append('languageIso', languageISO)
			urlKeys.searchParams.append('countryIso', countryISO.toUpperCase())

			if (keys?.length) {
				urlKeys.searchParams.append('keys', keys.join(','))
			}

			return doFetchKeys
				? fetch(urlKeys, {
						next: { revalidate: 0 },
					}).then((res) => res.json())
				: null
		},
		{ suspense }
	)

	const { data: dataNamespace } =
		useSWRImmutable<LabelsTranslationsService | null>(
			[namespaces],
			() => {
				const urlNamespace = new URL(url)
				urlNamespace.searchParams.append('languageIso', languageISO)
				urlNamespace.searchParams.append('countryIso', countryISO.toUpperCase())

				if (namespaces?.length) {
					urlNamespace.searchParams.append('namespaces', namespaces.join(','))
				}

				return doFetchNamespaces
					? fetch(urlNamespace, {
							next: { revalidate: 0 },
						}).then((res) => res.json())
					: null
			},
			{ suspense }
		)

	const data = useMemo(
		() => ({
			...(dataNamespace ? adapterLabelsTranslations(dataNamespace) : {}),
			...(dataKeys ? adapterLabelsTranslations(dataKeys) : {}),
		}),
		[dataKeys, dataNamespace]
	)

	return useMemo(
		() => ({
			labels: data,
			t: (key: string) => data?.[key] || key,
		}),
		[data]
	)
}
