import type { Labels } from 'types/labels'

import type { LabelsTranslationsService } from '../../../types'

export function adapterLabelsTranslations(
	labels: LabelsTranslationsService
): Labels {
	const adaptedLabels: Labels = {}
	const keys = labels.keys

	for (const key in keys) {
		adaptedLabels[key] = keys[key].value
	}

	return adaptedLabels
}
